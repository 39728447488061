import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faStamp,
  faEarthAmericas,
  faWarehouse,
  faHistory,
  faStore,
  faRandom,
} from "@fortawesome/free-solid-svg-icons"

export default function ContactWhyComponent() {
  return (
    <div className="bg-light-gray shadow-custom-in">
      <div className="flex flex-col container max-w-screen-xl pt-14 pb-6 items-center">
        <div className="text-center w-full mb-4">
          <h2 className="mb-0">Why Choose Surplus Solutions?</h2>
        </div>
        <p className="font-light pb-5 md:pb-10">
          Surplus Solutions has more than 25+ years of experience in asset
          management, so we’re able to help you maximize the value of your
          investment when buying or selling surplus lab equipment. We combine
          deep expertise, comprehensive inventory, and global coverage. We also
          offer logistics services that cover everything from pre-move planning
          to rigging, packaging, storage, and transport. Plus, we work with
          industry-leading manufacturers, ensuring you get access to the highest
          quality lab, packaging, and processing equipment. All of this enables
          us to develop effective, customized long-term solutions for your lab
          assets.
        </p>

        <div className="flex flex-col justify-between w-full space-y-8 px-8 md:px-0">
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-12 items-stretch space-y-6 md:space-y-0">
            <div className="flex items-center bg-white py-5 px-8 rounded-md w-full md:w-1/3">
              <FontAwesomeIcon
                size="2xl"
                icon={faStamp}
                className="mr-4 text-red"
              />
              <h4 className="mb-0">Appraisal by Certified Professionals</h4>
            </div>
            <div className="flex items-center bg-white py-5 px-8 rounded-md w-full md:w-1/3">
              <FontAwesomeIcon
                size="2xl"
                icon={faEarthAmericas}
                className="mr-4 text-red"
              />
              <h4 className="mb-0">Global Clientele & Coverage</h4>
            </div>
            <div className="flex items-center bg-white py-5 px-8 rounded-md w-full md:w-1/3">
              <FontAwesomeIcon
                size="2xl"
                icon={faWarehouse}
                className="mr-4 text-red"
              />
              <h4 className="mb-0">Extensive, High Quality Inventory</h4>
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-12 items-stretch space-y-6 md:space-y-0 pb-8">
            <div className="flex items-center bg-white py-5 px-8 rounded-md w-full md:w-1/3">
              <FontAwesomeIcon
                size="2xl"
                icon={faHistory}
                className="mr-4 text-red"
              />
              <h4 className="mb-0">Long-term, Value-Based Solutions</h4>
            </div>
            <div className="flex items-center bg-white py-5 px-8 rounded-md w-full md:w-1/3">
              <FontAwesomeIcon
                size="2xl"
                icon={faStore}
                className="mr-4 text-red"
              />
              <h4 className="mb-0">
                Comprehensive Services to Buy,
                <br />
                Sell, or Relocate
              </h4>
            </div>
            <div className="flex items-center bg-white py-5 px-8 rounded-md w-full md:w-1/3">
              <FontAwesomeIcon
                size="2xl"
                icon={faRandom}
                className="mr-4 text-red"
              />
              <h4 className="mb-0">
                Hybrid Selling Strategies to
                <br /> Maximize Results
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
