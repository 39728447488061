import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import BottomHeroComponent from "../components/herocomponents/bottomherocomponent"
import Maximizing from "../components/bodycomponents/maximizing"
import R_BLUE_BG from "../images/background-images/bg-royal-blue.svg"
import { colors } from "../utils/colors"
import GRAY_ARROW_BG from "../images/SSLLC_Arrow_Movement.svg"
import FormComponent from "../components/bodycomponents/form-component"
import ContactLocationComponent from "../components/bodycomponents/contactlocation"
import ContactWhyComponent from "../components/bodycomponents/contactwhy"

// import InquiryForm from "../components/buy-equipment/inquiry-form"

const ContactUs = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Contact Surplus Solutions | Get in Touch with Our Team`}
          description={`Get in touch with Surplus Solutions for inquiries about appraisals, equipment sales, logistics, and relocation services.`}
        />
        <BottomHeroComponent
          backgroundImage={R_BLUE_BG}
          backgroundColor={colors.blue}
          fontShadowColor={colors.royalBlue}
          shadowColor="shadow-royalBlue-top"
          pageTitle="Contact Us"
          title="Surplus Solutions: Not Just Assets. Answers."
          body={
            <>
              <div className="flex flex-col md:flex-row justify-between gap-x-6 gap-y-4">
                <div className="w-full md:w-1/2">
                  <FormComponent formType="Contact" />
                </div>

                <div className="w-full md:w-1/2 text-left">
                  <p>
                    Looking to buy or sell lab, packaging, or processing
                    equipment? Need expert advice on relocating a lab or
                    managing all of your assets? Surplus Solutions is here to
                    help.
                  </p>
                  <br />
                  <p>
                    Our expert team specializes in equipment lifecycle
                    management for the biotech, pharmaceutical, and life
                    sciences industries. With strategically located facilities
                    across the U.S. and Europe, and decades of experience with
                    equipment of all kinds, we offer personalized local support
                    and an infrastructure with global reach.
                  </p>
                  <br />
                  <p>
                    Connect with us today to maximize the value of your
                    equipment and streamline your operations. Fill out the form,
                    or come check out us—and our impressive warehouses—for
                    yourself.
                  </p>
                </div>
              </div>
            </>
          }
        />

        <ContactLocationComponent />
        <ContactWhyComponent />
        <Maximizing
          backgroundImage={GRAY_ARROW_BG}
          title="Check Out Our Latest Arrivals"
          body={
            <p>
              New equipment arrives almost daily at Surplus Solutions, including
              some of the lab, packaging, and processing industries’ most unique
              and hard to find equipment. These items move quickly—act fast to
              secure yours while they last at competitive prices.
            </p>
          }
          to="/catalog/new-arrivals"
          buttonTitle="See New Arrivals"
          buttonColor={colors.royalBlue}
          hoverColor={colors.blue}
        />
      </Layout>
    </>
  )
}

export default ContactUs
